_.mixin({
    'deepPick': function(object, keys) {

        var obj = {}

        _.each(keys, function(key) {
            _.set(obj, key, _.get(object, key))
        })

        return obj
    }
})