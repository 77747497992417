angular
    .module('Solumax.Pagination', [])
    .directive('pagination', function(
        $timeout) {

        return {
            template: '<div class="row" ng-if="pagination"> <div class="col-xs-2 col-2"><i ng-if="pagination.current_page > 1" ng-click="loadPage(pagination.current_page - 1)" class="fa fa-chevron-left"></i></div><div class="col-xs-8 col-8 text-center"><small style="color: #606060;">Halaman ke <input class="form-not-applied" type="number" ng-model="pagination.current_page" ng-blur="loadPage(pagination.current_page)" style="max-width:3em;" min="1"> dari{{pagination.total_pages}}halaman ({{pagination.total}}total data; <input class="form-not-applied" type="number" ng-model="pagination.per_page" ng-blur="loadPage(pagination.current_page)" style="max-width:4em;" min="1"> data per halaman)</small></div><div class="col-xs-2 col-2 text-right"><i ng-if="pagination.current_page < pagination.total_pages" ng-click="loadPage(pagination.current_page + 1)" class="fa fa-chevron-right"></i></div></div>',
            restrict: 'E',
            scope: {
                pagination: '=', // pased from view to directive
                perPage: '=', // pased from view to directive
                page: '=', // passed from directive to view
                onLoadPage: '&' // called by directive
            },
            transclude: true,
            link: function(scope, elem, attrs) {
                
                $(window).on('keydown', function(e) {
                	if (e.keyCode === 188 && scope.pagination.current_page > 1) {
                		scope.loadPage(scope.pagination.current_page - 1)
                	}

                	if (e.keyCode === 190 && (scope.pagination.current_page < scope.pagination.total_pages) ) {
                		scope.loadPage(scope.pagination.current_page + 1)
                	}
                });

                scope.loadPage = function(page) {

                    scope.page = page

                    if (scope.perPage) {
                        scope.perPage = scope.pagination.per_page
                    }

                    $timeout(function() {
                        scope.onLoadPage();
                    }, 100);
                }

            }
        };
    })
    .filter('fromDateTimeString', function() {
        return function(text, format, initialFormat) {


            var date

            if (!text) {
                return ''
            }

            date = moment(text, initialFormat || 'YYYY-MM-DD HH:mm:ss')

            if (!date.isValid()) {
                date = moment(text)
            }

            return date.format(format || 'YYYY-MM-DD')
        }
    })