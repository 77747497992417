angular
    .module('Solumax.PageTitle', [])
    .run(function($rootScope, $state,
        JwtValidator) {

        $rootScope.$on('$stateChangeSuccess', function() {
            $rootScope.pageTitle = $state.current.pageTitle;

            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = '/application-files/icons/' + JwtValidator.decodedJwt.selected_tenant_id + '.ico?v=' + JwtValidator.decodedJwt.selected_tenant_id ;
            document.getElementsByTagName('head')[0].appendChild(link);
        });


      	  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {

            var elements = document.getElementsByClassName('modal');

            for (var i = elements.length - 1; i >= 0; i--) {

                if ($('#' + elements[i].id).hasClass('in')) {
                    $('#' + elements[i].id).modal('hide')
                    event.preventDefault()
                }
            }

        })
    });